<template>
	<div>
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveAddHealthPackageVisible"
			width="640px"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">新增套餐</span>
			<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				<el-form-item label="套餐名称" prop="title" class="from-item-third">
					<el-input v-model.trim="ruleForm.title" autocomplete="off" clearable placeholder="请填写套餐名称"></el-input>
				</el-form-item>
				<el-form-item label="服务医师" prop="doctor_id" class="from-item-second">
					<el-select v-model="ruleForm.doctor_id"
										 filterable
										 placeholder="请选择">
						<el-option
							v-for="item in doctorList"
							:key="item.id"
							:label="item.doctor_name"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="套餐价格" prop="price" class="from-item-second">
					<el-input v-model.trim="ruleForm.price" autocomplete="off" clearable placeholder="请填写套餐价格"></el-input>
				</el-form-item>
				<el-form-item label="套餐内容" prop="healthPackageType" class="from-item-second from-health-package" >
					<el-select v-model="healthPackageType"
										 filterable
										 placeholder="请选择"
										 @change="handleSelectHealthPackage">
						<el-option
							v-for="item in healthPackageTypeList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					
					<el-input v-model.number.trim="healthPackageTypeTimes"
										@change="handleSelectHealthPackage(healthPackageType)"
										style="width: 80px; margin-left: 15px"
										autocomplete="off" clearable placeholder="次数">
					</el-input><span style="margin-left: 8px; font-size: 14px;color: #333">次</span>
				
				</el-form-item>
				<!--<el-form-item label="有效期" prop="expired_days" class="from-item-second">-->
				<!--	<el-input v-model.number="ruleForm.expired_days" autocomplete="off" clearable-->
				<!--						placeholder="请填写有效期(0 表示永久有效)"></el-input>-->
				<!--</el-form-item>-->
				<el-form-item label="状态" prop="status" class="from-item-second">
					<el-radio-group v-model="ruleForm.status">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {getButlerDoctorList, postButlerAddDemand, putButlerUpdateDemand} from '@/api/cdm/cdm-housekeeper.js'

export default {
  name: "AddHealthPackage",
  props: {
    isActiveAddHealthPackageVisible: {
      type: Boolean,
      default: false
    },
    packageType: {
      type: String,
      default: '添加'
    },
    packageInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  created () {
    this.getList()
  },
  data () {
    let ruleForm = {
      title: '',
      doctor_id: '',
      price: '',
      pic_count: '',
      video_count: '',
      expired_days: '',
      status: 1
    }
		
    if (this.packageType === '编辑') {
      ruleForm = this.packageInfo
    }

    let healthPackageType =  'video_count'
    let healthPackageTypeTimes = 0
    if (ruleForm.video_count > 0) {
      healthPackageType =  'video_count'
      healthPackageTypeTimes = ruleForm.video_count
		} else {
    	healthPackageType =  'pic_count'
      healthPackageTypeTimes = ruleForm.pic_count
		}
    
		
    

    let validatePrice = (rule, value, callback) => {
      const checkPrice = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/
      if (checkPrice.test(value)) {
        callback()
      } else {
        callback(new Error('请填写套餐价格, 必须是数字(小数点后只能2位'))
      }
    }
    let interrogationType = (rule, value, callback) => {
      const checkNum = /^\d+$/
      if (checkNum.test(this.ruleForm.video_count) && checkNum.test(this.ruleForm.pic_count)) {
        callback()
      } else if (checkNum.test(this.ruleForm.video_count) && !this.ruleForm.pic_count) {
        callback()
      } else if (checkNum.test(this.ruleForm.pic_count) && !this.ruleForm.video_count)
        callback()
      else {
        callback(new Error('请填写问诊类型,必须是数字'))
      }
    }
    let checkHealthPackageType = (rule, value, callback) => {
      const checkNum = /^\d+$/
			if (checkNum.test(this.healthPackageTypeTimes)) {
			  callback()
			} else {
        callback(new Error('请填写问诊次数,必须是数字'))
			}
		}
    return {
      listLoading: false,
			healthPackageTypeList: [{
        value: 'video_count',
				label: '视频问诊'
			},{
        value: 'pic_count',
        label: '图文问诊'
      }],
      healthPackageType,
      healthPackageTypeTimes,
      doctorList: [],
      ruleForm,
      rules: {
        title: [
          { required: true, message: '请填写套餐名称', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
        ],
        doctor_id: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请填写套餐价格', trigger: 'blur' },
          { validator: validatePrice, message: '请填写套餐价格, 必须是数字(小数点后只能2位)', trigger: 'blur' },
        ],
				type: [
          { required: true, message: '请选择', trigger: 'blur' },
					{validator: checkHealthPackageType, message: '请填写套餐次数,必须是数字', trigger: 'blur'}
				],
        video_count: [
          { validator: interrogationType, message: '请填写套餐次数,必须是数字', trigger: 'blur' },
        ],
        pic_count: [
          { validator: interrogationType, message: '请填写套餐次数,必须是数字', trigger: 'blur' },
        ],
        expired_days: [
          { required: true, message: '请填写有效期', trigger: 'blur' },
          { type: 'number', message: '请填写数字', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    getList (rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = { ...this.searchParams }
      getButlerDoctorList(params).then(response => {
        let data = response
        if (data.code === 200) {
          this.doctorList = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _postButlerAddDemand (data) {
      this.listLoading = true
      postButlerAddDemand(data).then(response => {
        let data = response
        if (data.code == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          })
          this.$emit('getList')
          this.handleConfirm()
        } else {
          this.$message({
            showClose: true,
            message: data.message,
            type: 'error'
          })
        }
        this.listLoading = false
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络错误',
          type: 'success'
        })
        this.listLoading = false
      })
    },
    _putButlerUpdateDemand (row) {
      this.listLoading = true
      putButlerUpdateDemand(row.id, row).then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.$emit('getList')
          this.handleConfirm()
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    },
    handleSelectHealthPackage(type) {
      console.log(type)
      this.ruleForm.pic_count = this.ruleForm.video_count = 0
      this.ruleForm[type] = this.healthPackageTypeTimes
		},
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.packageType === '添加') {
            this._postButlerAddDemand(this.ruleForm)
          } else {
            this._putButlerUpdateDemand(this.ruleForm)
					}
					
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.ruleForm.video_count = ''
    },
    handleConfirm () {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveAddHealthPackageVisible', false)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveAddHealthPackageVisible', false)
    },
  }
}
</script>

<style scoped lang="scss">
	.mgl-20 {
		margin-left: 20px;
	}
	
	.mgr-20 {
		margin-right: 20px;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
		margin-right: 20px;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: 590px;
		}
	}
	
	/deep/ .el-cascader {
		width: 280px;
	}
	
	/deep/ .el-select {
		width: 280px !important;
	}
	
	/deep/ .el-date-editor {
		width: 280px !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
	
	
	.from-health-package{
		/deep/ .el-select {
			width: 160px !important;
		}
	}
</style>
