<template>
	<div class="">
		<div class="gg-container">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>套餐名称：</label>
					<el-input
						size="small"
						placeholder="请输入套餐包名称"
						prefix-icon="el-icon-search"
						v-model="searchParams.keywords"
						clearable
						@change="getList"
					>
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>服务医师：</label>
					<el-select
						v-model="searchParams.doctor_id"
						placeholder="请选择"
						size="small"
						clearable
						filterable
						@change="getList"
					>
						<template slot="prefix">
							<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
						</template>
						<el-option
							v-for="item in doctorList"
							:key="item.id"
							:label="item.doctor_name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				
				<div class="search-container-fn-input" style="margin-left: auto;">
					<el-button
						size="mini"
						type="primary"
						style="margin-left: 20px; padding: 6px 7px 7px 8px;  border-color: #2362FB"
						@click="handleAddHealthPackage('添加')">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">添加</span>
					</el-button>
				</div>
			</div>
			
			<!--	table  -->
			<el-table
				border
				v-loading="listLoading"
				:header-cell-style="{'text-align':'center'}"
				:data="tableData"
				style="width: 100%">
				<el-table-column
					fixed
					label="ID"
					type="index"
					align="center"
					width="80">
				</el-table-column>
				<el-table-column
					prop="title"
					label="套餐名称"
					align="center">
				</el-table-column>
				<el-table-column
					prop="doctor_name"
					label="服务医师"
					align="center"
					width="120">
				</el-table-column>
				<el-table-column
					prop="content"
					label="套餐内容"
					align="center"
					width="280">
				</el-table-column>
				<el-table-column
					prop="price"
					label="套餐价格"
					align="center"
					width="120">
				</el-table-column>
				<el-table-column
					prop="price"
					label="套餐价格"
					align="center"
					width="120">
				</el-table-column>
				<el-table-column
					prop="status"
					align="center"
					label="状态"
					width="120">
					<template slot-scope="scope">
						<el-switch
							class="switch"
							@change="changeExamine(scope.row)"
							v-model="scope.row.status"
							:active-value="1"
							:inactive-value="0"
							active-text="启用"
							inactive-text="禁用"
							active-color="#13ce66"
							inactive-color="#C8C8C8">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column
					prop="admin_name"
					align="center"
					label="创建人"
					width="120">
				</el-table-column>
				<el-table-column
					prop="created_at"
					align="center"
					label="创建时间"
					width="190">
				</el-table-column>
				<el-table-column align="center" width="200" label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-edit"
											 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;border-color: #4EBBFC"
											 @click="handleEdit(scope.$index, scope.row)">编辑
						</el-button>
						<el-button size="mini" type="danger" icon="el-icon-delete"
											 style="padding: 7px 8px;margin-left: 5px;" @click="handleDelete(scope.$index, scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<!--pagination-->
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="getList"/>
		</div>
		<!--添加, 修改健康套餐弹出框-->
		
		<add-health-package v-if="isActiveAddHealthPackageVisible"
												:isActiveAddHealthPackageVisible.sync="isActiveAddHealthPackageVisible"
												:packageType="packageType"
												:packageInfo="packageInfo"
												@getList="getList">
		</add-health-package>
		

	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import AddHealthPackage from './components/AddHealthPackage';
import {putAppraiseExamine} from '@/api/informationSearch';
import {
  getButlerDemandList,
  delButlerDelDemand,
  putButlerUpdateDemand,
  getButlerDoctorList
} from '@/api/cdm/cdm-housekeeper.js';

export default {
  name: "HealthPackage",
  components: { Pagination, AddHealthPackage },
  created () {
    this.getList()
    this._getButlerDoctorList()
  },
  data () {
    return {
      packageType: '添加',
      total: 0,
      listLoading: false,
      searchParams: {
        keywords: '',
        doctor_id: ''
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
      },
      doctorList: [],
      tableData: [],
      isActiveAddHealthPackageVisible: false,
      packageInfo: {}
    }
  },
  methods: {
    getList (rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = { ...this.searchParams }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getButlerDemandList(params).then(response => {
        let data = response
        if (data.code === 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _getButlerDoctorList (rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      let params = { ...this.searchParams }
      getButlerDoctorList(params).then(response => {
        let data = response
        if (data.code === 200) {
          this.doctorList = data.data
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleAddHealthPackage (packageType) {
      this.packageType = packageType
      this.isActiveAddHealthPackageVisible = true
    },
    changeExamine (row) {  //选择状态 (启用/禁用)
      this.listLoading = true
      putButlerUpdateDemand(row.id, row).then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    },
    handleEdit (index, row) {
      console.log(row)
      this.packageInfo = row
      this.handleAddHealthPackage('编辑')
    },
    handleDelete (index, row) {
      console.log(index, row);
      this.$confirm('确认删除套餐包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        delButlerDelDemand(row.id).then(res => {
          if (res.code === 200) {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
          this.listLoading = false
        }).catch(error => {
          this.$message({
            type: 'error',
            message: '网络错误'
          })
          this.listLoading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;
		
		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			margin-right: 8px;
			border-radius: 2px;
		}
	}
	
	
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
